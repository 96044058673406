import classes from './Wave.module.css';

const Wave = () => {
    return (
        <div className={classes.ocean}>
            <div className={classes.wave}></div>
            <div className={classes.wave}></div>
        </div>
    )
}

export default Wave;