import classes from './OurServices.module.css';
import FlexColumnDiv from '../UI/FlexColumnDiv';
import Title from '../UI/Title';
import UnderlineBlue from '../UI/UnderlineBlue';
import Text from '../UI/Text';
import ComplexProject from './ComplexProject';
import CloudSolutions from './CloudSolutions';
import DevOps from './DevOps';

const OurServices = () => {
    return (
        <FlexColumnDiv>
            <div className={`animation-bottom ${classes.servicesTitle}`}>
                <Title>Our Services</Title>
                <UnderlineBlue />
                <Text>We help our Clients cross the Digital Chasm and accomplish true Digital Transformation by rapidly integrating our top tier engineering talent into their organizations.</Text>
            </div>
            <ComplexProject />
            <CloudSolutions />
            <DevOps />
        </FlexColumnDiv>
    )
};

export default OurServices;