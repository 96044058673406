import { Link } from 'react-router-dom';
import { HOME } from '../../../constants/routes';
import logo from '../../../assets/logo/sbs_logo.png';
import classes from './NavLogo.module.css';
import UnderlineDarkBlue from '../../UI/UnderlineDarkBlue';

const NavLogo = () => {
    return (
            <Link to={HOME} className={classes.navLogo}>
                <img src={logo} alt="logo" />
                <div className={classes.logoTextContainer}>
                    <p className={classes.logoText}>SBS DEVOPS</p>
                    <UnderlineDarkBlue />
                </div>
            </Link>
    )
};

export default NavLogo;