import classes from './Footer.module.css';
import Title from '../UI/Title';
import UnderlineBlue from '../UI/UnderlineBlue';
import FlexColumnDiv from '../UI/FlexColumnDiv';
import Hr from '../UI/Hr';
import ContactIcons from './/ContactIcons';
import NavLinks from '../header/nav-bar/NavLinks';
import Copyright from './Copyright';

const Footer = () => {
    return (
        <FlexColumnDiv>
            <Title>Contact Us!</Title>
            <UnderlineBlue />
            <ContactIcons />
            <Hr />
            <NavLinks className={classes.footerNav} />
            <Copyright />
        </FlexColumnDiv>
    )
};

export default Footer;