import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import Industries from '../components/industries/Industries';

const IndustriesPage = () => {
    return (
        <section className='backgroundWorld'>
            <Header />
            <Industries />
            <Footer />
        </section>
    )
};

export default IndustriesPage;