import React from 'react';
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import classes from './ContactIcons.module.css';
import FlexRowDiv from '../UI/FlexRowDiv';

const ContactIcons = () => {
    return (
        <React.Fragment>
            <div className={classes.iconContainer}>
                <a className={classes.icon} href="https://www.facebook.com/siliconbeachsolutions/" target='_blank' rel='noopener noreferrer'><FaFacebookSquare /></a>
                <a className={classes.icon} href="https://twitter.com/tweet_sbs" target='_blank' rel='noopener noreferrer'><FaTwitterSquare /></a>
                <a className={classes.icon} href="https://www.linkedin.com/company/siliconbeachsolutions" target='_blank' rel='noopener noreferrer'><FaLinkedin /></a>
            </div>
            <FlexRowDiv>
                            <div className={classes.iconContainer}>
                                <FaMapMarkerAlt />
                                <p>Newport Beach, California, USA</p>
                            </div>
                            <div className={classes.iconContainer}>
                                <FaPhoneAlt />
                                <p>310-809-6695</p>
                            </div>
                            <div className={classes.iconContainer}>
                                <FaEnvelope />
                                <p>chat@sbsdevops.com</p>
                            </div>
            </FlexRowDiv>
        </React.Fragment>
    )
};

export default ContactIcons;