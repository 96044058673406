import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import classes from './Slider.module.css';

const Slider = (props) => {
    const sliderWrapper = {
        position: 'relative',
        width: '100%',
        height: props.sliderHeight,
        marginBottom: '5.5rem'
    }
    return (
        <div style={sliderWrapper} className={classes.sliderWrapper}>
            <AwesomeSlider fillParent={true} className={classes.slider}>
                {props.children}
            </AwesomeSlider>
        </div>
    )
};

export default Slider;