import React from 'react';
import classes from './ContactUs.module.css';
import Title from '../UI/Title';
import UnderlineBlue from '../UI/UnderlineBlue';
import Text from '../UI/Text';
import ContactIcons from '../footer/ContactIcons';



const ContactUs = () => {
    return (
        <div className={`${classes.contactContainer} animation-bottom`}>
            <Title>Let's chat</Title>
            <UnderlineBlue />
            <Text>Whether you’re looking for answers, would like to solve a problem, or just want to say hello, feel free to contact us right here.</Text>          
            <ContactIcons />
        </div>
    )
}
export default ContactUs;