import classes from './ComplexProject.module.css';
import { GiCheckMark } from 'react-icons/gi';
import FlexRowDiv from '../UI/FlexRowDiv';
import Title from '../UI/Title';
import UnderlineBlue from '../UI/UnderlineBlue';
import Text from '../UI/Text';
import ComplexProjectImg from './assets/complex_project.gif';

const ComplexProject = () => {
    return (
        <FlexRowDiv>
            <div className={classes.imageContainer}><img src={ComplexProjectImg} alt="Complex IT Project Delivery" /></div>
            <div className={classes.contentContainer}>
                <Title>Complex IT Project Delivery</Title>
                <UnderlineBlue />
                <Text>Use our IT Project Delivery Experts for Start-to-Finish success!</Text>
                <ul className={classes.list}>
                    <li><GiCheckMark color='#77a9f2' /> SCRUM Practitioners</li>
                    <li><GiCheckMark color='#77a9f2' /> AGILE Transformers</li>
                    <li><GiCheckMark color='#77a9f2' /> Program and Project Managers</li>
                    <li><GiCheckMark color='#77a9f2' /> Product Owners and Product Managers</li>
                    <li><GiCheckMark color='#77a9f2' /> Business Analysts</li>
                    <li><GiCheckMark color='#77a9f2' /> QA Testers (Automation or Manual)</li>
                    <li><GiCheckMark color='#77a9f2' /> Architects and Developers</li>
                </ul>
            </div>
        </FlexRowDiv>
    )
};

export default ComplexProject;