import Header from '../components/header/Header';
import Hero from '../components/home/Hero';
import DevOps from '../components/home/dev-ops/DevOps';
import CaliforniaMade from '../components/home/CaliforniaMade';
import TeamSlider from '../components/home/team/TeamSlider';
import ClientSlider from '../components/home/ClientSlider';
import Footer from '../components/footer/Footer';

const HomePage = () => {
    return (
        <section className='backgroundCurve'>
            <Header />
            <Hero />
            <DevOps />
            <CaliforniaMade />
            <TeamSlider />
            <ClientSlider />
            <Footer />
        </section>   
    )
};

export default HomePage;