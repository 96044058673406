import React from 'react';
import { Link } from 'react-router-dom';
import { HOME, WHY_CHOOSE_US, OUR_SERVICES, INDUSTRIES, JOB_OPENINGS, CONTACT_US } from '../../../constants/routes';

const NavLinks = (props) => {
    return (
        <div className={props.className}>
            <Link to={HOME}>Home</Link>
            <Link to={WHY_CHOOSE_US}>Why Choose Us</Link>
            <Link to={OUR_SERVICES}>Our Services</Link>
            <Link to={INDUSTRIES}>Industries</Link>
            <Link to={JOB_OPENINGS}>Job Openings</Link>
            <Link to={CONTACT_US}>Contact Us</Link>
        </div>
    )
};

export default NavLinks;