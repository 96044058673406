import classes from './ClientSlider.module.css';

const ClientSlider = () => {
    const clients = [
        'Fox Corporation', 
        'Fox Next Games', 
        'The Walt Disney Company',
        'Hyundai Autoever America',
        'Hyundai Motor America',
        'DirecTVLA/AT&T',
        'Toyota',
        'KDP',
        'Equinix'
    ];
    return (
        <div className={classes.slider}>
            <div className={classes.slideTrack}>
                {clients.map((slide, index) => {
                    return (
                        <div key={index} className={classes.slide}>
                            <h1>{slide}</h1>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default ClientSlider;