import classes from './TeamSlider.module.css';
import Slider from "../../UI/Slider";
import Team, { TeamMobile } from './Team';
import OurPriority from './OurPriority';
import HappyBunch from './HappyBunch';
import React from 'react';
import Emmy, { EmmyMobile } from './Emmy';
import Halloween from './Halloween';

const TeamSlider = () => {
    return (
        <React.Fragment>
        <Slider sliderHeight='60rem'>
            <div><Team /></div>
            <div><Emmy /></div>
            <div><Halloween /></div>
            <div><OurPriority /></div>
            <div><HappyBunch /></div>
        </Slider>
        <div className={classes.team}>
            <TeamMobile />
            <EmmyMobile />
            <Halloween />
            <OurPriority />
        </div>
        </React.Fragment>
    )
};

export default TeamSlider;