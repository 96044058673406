import classes from './Team.module.css';
import TeamCard, { TeamCardMobile } from './TeamCard';
import Title from '../../UI/Title';
import UnderlineBlue from '../../UI/UnderlineBlue';
import FlexColumnDiv from '../../UI/FlexColumnDiv';

import AndrewPhoto from '../assets/team/IMG_3839_edited.webp';
import NicPhoto from '../assets/team/NR-Profile_Image.png';
import DannyPhoto from '../assets/team/IMG-0507_edited.webp';
import PatPhoto from '../assets/team/pat_photo.jpg';
import TimPhoto from '../assets/team/tim-horist-web.jpg';
import MajaPhoto from '../assets/team/IMG-6636.jpg';

const Team = () => {
    return (
        <div className={classes.teamContainer}>
            <Title>Some Of Our Team</Title>
            <UnderlineBlue />
            <div className={classes.teamSection}>
                <TeamCard src={NicPhoto} alt="Team Photo" name="Nic" description="Having worked at innovative leaders and disruptors in Silicon Valley, including SGI and General Magic, Nic is extremely passionate about bringing forward the Digital Transformation leveraging scalable cloud solutions and toolkits.  His implementations include Toyota Motor North America, Toyota Financial Services, Sony, Fox, and KDP." />
                <TeamCard src={AndrewPhoto} alt="Team Photo" name="Andrew" description="Started as a Business Analyst, supporting digital marketing implementations at Toyota Motor North America.  Since then, he completed his MBA at Pepperdine and came right back into digital marketing as an engagement manager and program manager supporting Hyundai Motor America. " />
                <TeamCard src={DannyPhoto} alt="Team Photo" name="Danny" description="An expert AWS Enterprise Systems Engineer, Danny naturally added Cloud DevOps to his skillset.  Danny has honed his expertise in large and very complex IT organizations, including Toyota Motor North America, JPL, and Fox." />
            </div>
            <div className={classes.teamSection}>
                <TeamCard src={MajaPhoto} alt="Team Photo" name="Maja" description="Maja is our Front-end web developer. She specializes in developing React applications and is passionate about creating accessible user interfaces on the web. When not coding behind a laptop keyboard, she loves traveling and exploring new places. " />
                <TeamCard src={PatPhoto} alt="Team Photo" name="Pat" description="Pat is our Sr. Cloud Technical Product and Delivery specialist with proficiency in cloud platforms. Servant leadership in Agile methodologies such as Scrum and SAFe. Certifications in Salesforce Certified Administrator, SAFe Agilist, DevOps, Certified Scrum Master. Hold an MS in IT Management from Carnegie Mellon University." />
                <TeamCard src={TimPhoto} alt="Team Photo" name="Tim" description="Tim has been developing full-stack web applications and training new software engineers since 2016. Before that, he got 5 years of experience running procurement and logistics for a large industrial equipment manufacturer. Tim has a B.S. in Physics from UCI." />
            </div>
        </div>
    )
};

const TeamMobile = () => {
    return (
        <FlexColumnDiv>
            <Title>Some Of Our Team</Title>
            <UnderlineBlue />
            <div className={classes.teamMobile}>
                <TeamCardMobile src={NicPhoto} alt="Team Photo" name="Nic" description="Having worked at innovative leaders and disruptors in Silicon Valley, including SGI and General Magic, Nic is extremely passionate about bringing forward the Digital Transformation leveraging scalable cloud solutions and toolkits.  His implementations include Toyota Motor North America, Toyota Financial Services, Sony, Fox, and KDP." />
                <TeamCardMobile src={AndrewPhoto} alt="Team Photo" name="Andrew" description="Started as a Business Analyst, supporting digital marketing implementations at Toyota Motor North America.  Since then, he completed his MBA at Pepperdine and came right back into digital marketing as an engagement manager and program manager supporting Hyundai Motor America. " />
                <TeamCardMobile src={DannyPhoto} alt="Team Photo" name="Danny" description="An expert AWS Enterprise Systems Engineer, Danny naturally added Cloud DevOps to his skillset.  Danny has honed his expertise in large and very complex IT organizations, including Toyota Motor North America, JPL, and Fox." />
                <TeamCardMobile src={MajaPhoto} alt="Team Photo" name="Maja" description="Maja is our Front-end web developer. She specializes in developing React applications and is passionate about creating accessible user interfaces on the web. When not coding behind a laptop keyboard, she loves traveling and exploring new places. " />
                <TeamCardMobile src={PatPhoto} alt="Team Photo" name="Pat" description="Pat is our Sr. Cloud Technical Product and Delivery specialist with proficiency in cloud platforms and on-prem technologies. Servant leadership in Agile methodologies such as Scrum and SAFe. Certifications in Salesforce Certified Administrator, SAFe Agilist, Scrum Master, DevOps, Certified Scrum Master. Hold an MS in IT Management from Carnegie Mellon University." />
                <TeamCardMobile src={TimPhoto} alt="Team Photo" name="Tim" description="Tim has been developing full-stack web applications and training new software engineers since 2016. Before that, he got 5 years of experience running procurement and logistics for a large industrial equipment manufacturer. Tim has a B.S. in Physics from UCI." />
            </div>
        </FlexColumnDiv>
    )
};

export default Team;
export { TeamMobile };