import { OUR_SERVICES } from '../../constants/routes';
import classes from './Hero.module.css';
import DevChart from './assets/dev_chart.svg';
import Inc5000 from '../../assets/logo/inc5000.png';
import Title from '../UI/Title';
import UnderlineBlue from '../UI/UnderlineBlue';
import Text from '../UI/Text';
import ButtonWhite from '../UI/ButtonWhite';

const Hero = () => {
    return (
        <div className={classes.hero}>
            <div className={`${classes.contentContainer} animation-bottom`}>
                <div className={classes.incImage}><img src={Inc5000} alt='Inc 5000' /></div>
                <Title>Digitally transform faster, smarter, and easier</Title>
                <UnderlineBlue />
                <Text>
                    Our experienced engineers will deliver the ultimate outcomes without the hiring hassles.
                </Text>
                <ButtonWhite to={OUR_SERVICES}>Find Out More</ButtonWhite>
            </div>
            <div className={classes.imageContainer}>
                <img src={DevChart} alt="Dev Ops Platforms" />
            </div>
        </div>
    )
};

export default Hero;