import classes from './WhyChooseUs.module.css';
import IncImg from '../../assets/logo/inc5000.png';
import PeopleTypingImg from './assets/people_typing.webp';
import { GiCheckMark } from 'react-icons/gi';
import FlexColumnDiv from '../UI/FlexColumnDiv';
import Title from '../UI/Title';
import UnderlineBlue from '../UI/UnderlineBlue';
import Text from '../UI/Text';


const WhyChooseUs = () => {
    return (
        <FlexColumnDiv>
            <div className={`${classes.center} animation-bottom`}>
                <Title>You Can Trust Us</Title>
                <UnderlineBlue />
                    <div className={classes.textContainer}>
                        <Text>
                            We deliver exceptional technology solutions for world-class businesses in every industry, from dynamic startups and SMB to Fortune 500 Companies.
                        </Text>
                        <Text>
                            COVID has brought about a very challenging time for everyone.  We continue to hire the best talent and support our clients without any disruption to quality and service.
                        </Text>
                        <img className={classes.incImg} src={IncImg} alt='Inc 5000' />
                    </div>
            </div>
            
            <div className={classes.contentWrapper}>
                    <ul className={classes.listContainer}>
                        <li><GiCheckMark color='#77a9f2' /> We consider our engineers to be in the top 1% talent.</li>
                        <li><GiCheckMark color='#77a9f2' /> Your corporate culture is an important part of our criteria.</li>
                        <li><GiCheckMark color='#77a9f2' /> Our support is aligned with your timezone and after-hour needs.</li>
                        <li><GiCheckMark color='#77a9f2' /> We create and integrate seamlessly with distributed teams.</li>
                        <li><GiCheckMark color='#77a9f2' /> We maintain extremely high customer and employee retention.</li>
                    </ul>
                <div className={classes.typingImg}><img src={PeopleTypingImg} alt='People Typing' /></div>
            </div>  
        </FlexColumnDiv>
    )
};

export default WhyChooseUs;