import classes from './Copyright.module.css';

const Copyright = () => {
    const year = new Date().getFullYear();
    return (
        <div className={classes.copyright}>
            <p>All Content Copyright &copy; {year} by Silicon Beach Solutions, LLC.  All Rights Reserved.</p>
            <p>Silicon Beach Solutions, LLC. is a comprehensive Business Technology Solutions Provider that specializes in Enterprise Agile Technology Digital Infrastructure, DevOps, and Atlassian implementations.</p>
        </div>
    )
}

export default Copyright;