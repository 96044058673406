import classes from './DevOps.module.css';
import { GiCheckMark } from 'react-icons/gi';
import Title from '../UI/Title';
import UnderlineBlue from '../UI/UnderlineBlue';
import Text from '../UI/Text';
import Video from '../UI/Video';
import FlexRowDiv from '../UI/FlexRowDiv';
import devOpsPhoto from './assets/dev_ops_gif.gif';
import desktopVideo from './assets/videos/AdobeStock_488284618.mp4';
import mobileVideo from './assets/videos/488284618_mobile.mov';

const DevOps = () => {
    return (
        <div className={classes.devopsContainer}>
            <div className={classes.contentContainer}>
                <Title>DevOps & Cloud SME Support</Title>
                <UnderlineBlue />
                <Text>Integrate our SME Support to unleash DevOps at your Enterprise.</Text>
                <img className={classes.devopsImg} alt="Dev Ops" src={devOpsPhoto} />
                <FlexRowDiv>
                    <ul className={classes.list}>
                        <li><GiCheckMark color='#77a9f2' /> Continuous Deployment Architecture</li>
                        <li><GiCheckMark color='#77a9f2' /> Strategic & Tactical Multi-Tier</li>
                        <li><GiCheckMark color='#77a9f2' /> Client Application Management</li>
                        <li><GiCheckMark color='#77a9f2' /> Automation & Terraform</li>
                    </ul>
                    <ul className={classes.list}>
                        <li><GiCheckMark color='#77a9f2' /> AWS Certified Expertise</li>
                        <li><GiCheckMark color='#77a9f2' /> DevOps Scripting Language Experts</li>
                        <li><GiCheckMark color='#77a9f2' /> Security & Hardening</li>
                        <li><GiCheckMark color='#77a9f2' /> Automated Testing Integrations</li>
                    </ul>
                    <ul className={classes.list}>
                        <li><GiCheckMark color='#77a9f2' /> Monitoring</li>
                        <li><GiCheckMark color='#77a9f2' /> Team Administration</li>
                        <li><GiCheckMark color='#77a9f2' /> Communication Channels</li>
                        <li><GiCheckMark color='#77a9f2' /> Agile SDLC Methodology</li>
                    </ul>
                </FlexRowDiv>
            </div>  
            <div className={classes.videoWrapper}><Video className={classes.video} desktopVideo={desktopVideo} tabletVideo={desktopVideo} mobileVideo={mobileVideo} /></div>
        </div>
    )
};

export default DevOps;