import HalloweenImg from '../assets/image004.png';
import Text from '../../UI/Text';

const Halloween = () => {
    const style = {
        container: {
            margin: '0 auto',
            width: '75%',
            height: '100%',
            textAlign: 'center'
        },
        img: {
            width: '65%',
            borderRadius: '3rem',
            boxShadow: '0 0 15px rgba(0,0,0,0.1)',
            margin: '2rem auto'
        }
    }
    return (
        <div style={style.container}>
            <Text>
                Travis (SBS Sr. PM) wins client Halloween contest!
            </Text>
            <img style={style.img} src={HalloweenImg} alt='Happy Halloween' />
        </div>
    )
}

export default Halloween;