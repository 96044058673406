import classes from './ContactUs.module.css';
import Header from '../components/header/Header';
import ContactUs from '../components/contact-us/ContactUs';
import Copyright from '../components/footer/Copyright';
import Video from '../components/UI/Video';
import desktopVideo from '../assets/videos/AdobeStock_327244599.mp4';
import mobileVideo from '../assets/videos/327244599_mobile.mov';


const ContactUsPage = () => {
    return (
        <section className={classes.position}>
            <Video className={classes.video} desktopVideo={desktopVideo} tabletVideo={desktopVideo} mobileVideo={mobileVideo} />
            <div className={classes.overlay}></div>
            <Header />
            <ContactUs />
            <Copyright />
        </section>
    )
};

export default ContactUsPage;