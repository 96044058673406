import EmmyImg from '../assets/IMG_1652.png';
import Text from '../../UI/Text';

const Emmy = () => {
    const style = {
        container: {
            margin: '0 auto',
            width: '65%',
            height: '100%',
            textAlign: 'center'
        },
        img: {
            width: '38%',
            borderRadius: '3rem',
            boxShadow: '0 0 15px rgba(0,0,0,0.1)',
            marginBottom: '2rem'
        }
    }
    return (
        <div style={style.container}>
            <img style={style.img} src={EmmyImg} alt='Emmy' />
            <Text>
                “The SBS DevOps Team received an Emmy for their excellent work in the successful technical delivery of 2022 FIFA World Cup!  Congrats to SBS DevOps Team.  You are Celebrities and have an Emmy to prove it!”
            </Text>
        </div>
    )
}

const EmmyMobile = () => {
    const style = {
        container: {
            margin: '0 auto',
            width: '70%',
            height: '100%',
            textAlign: 'center'
        },
        img: {
            width: '65%',
            borderRadius: '3rem',
            boxShadow: '0 0 15px rgba(0,0,0,0.1)',
            margin: '2rem auto 2rem'
        }
    }
    return (
        <div style={style.container}>
            <Text>
                “The SBS DevOps Team received an Emmy for their excellent work in the successful technical delivery of 2022 FIFA World Cup!  Congrats to SBS DevOps Team.  You are Celebrities and have an Emmy to prove it!”
            </Text>
            <img style={style.img} src={EmmyImg} alt='Emmy' />
        </div>
    )
}

export default Emmy;
export { EmmyMobile };