import React from 'react';
import { Link } from 'react-router-dom';
import classes from './ButtonWhite.module.css';

const ButtonWhite = (props) => {
    return (
        <Link
            className={classes.button}
            to={props.to}
        >
            {props.children}
        </Link>
    )
}

export default ButtonWhite;