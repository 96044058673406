import Title from '../../UI/Title';
import UnderlineBlue from '../../UI/UnderlineBlue';
import Text from '../../UI/Text';

const OurPriority = () => {
    const style = {
        container: {
            margin: '0 auto',
            width: '63%',
            height: '100%',
            textAlign: 'center'
        }
    }
    return (
        <div style={style.container}>
            <Title>Our Priority</Title>
            <UnderlineBlue />
            <Text>
                Our Vision at SBS DevOPs is to create a place of belonging and connectedness and be a partner of choice for your Organization to deliver its best work.
            </Text>
            <Text>
                Our Mission at SBS DevOps is to enable our Technology Organization partners to deliver value driven solutions for their Business through a process that is efficient, transparent, fit for purpose and is empowering to everyone (Business and IT).
            </Text>
            <Text>
                We are a minority owned business that continues to prioritize and promote the hiring of women and minorities in Technology.
            </Text>
        </div>
    )
}

export default OurPriority;