import classes from './TeamCard.module.css';

const TeamCard = (props) => {
    return (
        <div className={classes.card}>
            <div className={classes.content}>
                <div className={classes.front}>
                    <div className={classes.image}><img src={props.src} alt={props.alt} /></div>
                    <h1 className={classes.name}>{props.name}</h1>
                </div>
                <div className={classes.back}>
                    <p>{props.description}</p>
                </div>
            </div>
        </div>
    )
}

export const TeamCardMobile = (props) => {
    return (
        <div className={classes.cardMobile}>
            <img src={props.src} alt={props.alt} />
            <h1 className={classes.name}>{props.name}</h1>
        </div>
    )
}
export default TeamCard;