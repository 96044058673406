import React from "react";
import { Routes, Route } from "react-router-dom";

import { HOME, WHY_CHOOSE_US, OUR_SERVICES, INDUSTRIES, JOB_OPENINGS, CONTACT_US } from './constants/routes';
import HomePage from './pages/Home';
import WhyChooseUsPage from "./pages/WhyChooseUs";
import OurServicesPage from "./pages/OurServices";
import IndustriesPage from "./pages/Industries";
import JobOpeningsPage from "./pages/JobOpenings";
import ContactUsPage from "./pages/ContactUs";

function App() {
  return (
    <Routes>
      <Route path={HOME} element={<HomePage />} />
      <Route path={WHY_CHOOSE_US} element={<WhyChooseUsPage />} />
      <Route path={OUR_SERVICES} element={<OurServicesPage />} />
      <Route path={INDUSTRIES} element={<IndustriesPage />} />
      <Route path={JOB_OPENINGS} element={<JobOpeningsPage />} />
      <Route path={CONTACT_US} element={<ContactUsPage />} />
    </Routes>
  )
};

export default App;
