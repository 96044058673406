import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import OurServices from '../components/our-services/OurServices';

const OurServicesPage = () => {
    return (
        <section>
            <Header />
            <OurServices />
            <Footer />
        </section>
    )
};

export default OurServicesPage;