const Video = (props) => {
    const desktopVideo = props.desktopVideo;
    const tabletVideo = props.tabletVideo;
    const mobileVideo = props.mobileVideo;
    const getVideoSrc = width => {
        if (width >= 1080) return desktopVideo;
        if (width >= 720) return tabletVideo;
        return mobileVideo;
      };
      const src = getVideoSrc(window.innerWidth);
    return (
        <video className={props.className} autoPlay muted loop playsInline preload='auto' controls={false}>
                    <source src={src} type="video/mp4" />
                        Your browser is not supported!
        </video>
    ) 
}

export default Video;