import classes from "./JobOpenings.module.css";
import Title from '../UI/Title';
import UnderlineBlue from '../UI/UnderlineBlue';
import Text from "../UI/Text";
import Video from '../UI/Video';
import desktopVideo from './assets/AdobeStock_437212966.mp4';
import mobileVideo from './assets/437212966_mobile.mov';


const JobOpenings = () => {
    return (
            <div className={classes.jobsWrapper}>
                <div className={classes.peopleVideo}><Video className={classes.video} desktopVideo={desktopVideo} tabletVideo={desktopVideo} mobileVideo={mobileVideo} /></div>
                <div className={`${classes.contentContainer} animation-bottom`}>
                    <Title>Join our experts</Title>
                    <UnderlineBlue />
                    <a className={classes.btn} href='https://www.ziprecruiter.com/c/Silicon-Beach-Solutions/Jobs' target='_blank' rel='noopener noreferrer'>Jobs</a>
                    <Text>
                        COVID has brought about a very challenging time for everyone.  We continue to hire the best talent and support our clients without any disruption to quality and service.
                    </Text>
                    <Text>
                        Our current openings can be found here.  We invite qualified candidates to apply.
                    </Text>
                </div>
            </div>
    )
}

export default JobOpenings;