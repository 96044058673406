import classes from "./DevOpsCard.module.css";
import Text from "../../UI/Text";


const DevOpsCard = (props) => {
    const titleStyle = {
        fontSize: '2.5rem',
        padding: '1rem 0',
        color: props.titleColor
    };

    return (
        <div className={classes.card}>
            {props.icon}
            <h1 style={titleStyle}>{props.title}</h1>
            <h1 className={classes.subTitle}>{props.subTitle}</h1>
            <Text>{props.text}</Text>
        </div>
    )
};

export default DevOpsCard;