import classes from './CaliforniaMade.module.css';
import Title from '../UI/Title';
import Text from '../UI/Text';
import Hr from '../UI/Hr';

const CaliforniaMade = () => {
    return (
        <div className={classes.map}>
            <div className={classes.textContainer}>
                <Title>Made in California. Delivering Transformation Nationwide.</Title>
                <Hr />
                <Text>Founded in 2013 at the epicenter of the Southern Californian High Tech Hub affectionately known as ‘Silicon Beach’, the name Silicon Beach Solutions is apropos for an emerging Information Technology Company that specializes in Agile IT Consulting, DevOPs, Web Operations, and Resourcing... and believes in the highest quality delivery. </Text>
                <Text>We help companies scale through implementation of new and emerging technologies and automations, leveraging our CORE BELIEFS: PROFICIENCY, EFFICIENCY, PERSISTENCY, CONSISTENCY, TECHNOLOGY in order to deliver solutions that are: SIMPLE, SECURE, AGILE, AUTOMATED, SCALABLE, SELF-SERVICABLE.</Text>
            </div> 
        </div>
    )
};

export default CaliforniaMade;