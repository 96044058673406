import React from "react";
import classes from "./NavBar.module.css";
import NavLogo from './NavLogo';
import NavLinks from "./NavLinks";
import Wave from "../wave/Wave";

import { FaBars } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';

const NavBar = () => {
  return (
    <nav className={classes.nav}>
      <NavLogo />
      <input className={classes.chk} type="checkbox" id="chk" />
      <label htmlFor="chk" className={classes.showMenuBtn}>
        <FaBars color='black' size='27px' />
      </label>

      <div className={classes.menu}>
        <div className={classes.wave}>
          <Wave />
          <NavLogo />
        </div>
        <NavLinks className={classes.menuLinks} />
        <label htmlFor="chk" className={classes.hideMenuBtn}>
            <CgClose color='black' size='27px' />
        </label>
      </div>
    </nav>
  );
};

export default NavBar;