import classes from './Industries.module.css';
import FlexColumnDiv from '../UI/FlexColumnDiv';
import Title from '../UI/Title';
import UnderlineBlue from '../UI/UnderlineBlue';
import Text from '../UI/Text';
import Slider from '../UI/Slider';
import industriesImg from './assets/142884994_youtube-channel.jpg';

const Industries = () => {
    const year = new Date().getFullYear();
    return (
        <FlexColumnDiv> 
            <div className={`${classes.industriesContainer} animation-bottom`}>
                <Title>IT Solutions for Your Industry</Title>
                <UnderlineBlue />
                <Text>We are proud to play a part in the Digital Transformation of Fortune 500 Companies in Automotive, Entertainment & Media, Gaming, and Telecommunications, leveraging our amazing engineers.  Our clients vouch for us.</Text>
            </div>
            <div className={classes.industriesImg}><img src={industriesImg} alt="Industries" /></div>
            <Slider sliderHeight='46rem'>
                <div className={classes.sliderSection}>
                    <Title>Media & Entertainment (M&E)</Title>
                    <UnderlineBlue />
                    <p className={classes.sliderText}>
                        Our AWS and Azure Cloud Architects and DevOps Engineers are currently integrated with our M&E clients; supporting the mission critical HA/DR Cloud Infrastructures, Agile SDLCs, Automation Pipelines, and overall 24/7 Ops Support, of Digital Assets and their "digital media content supply chains."  Expertise also includes Enterprise Digital Asset Management Tools:  Aspera, Signiant, and OpenText MediaManagement (OTMM).
                    </p>
                    <p className={classes.sliderText}>
                        The United States Media and Entertainment (M&E) industry is a $703 billion market, comprised of businesses that produce and distribute motion pictures, television programs and commercials, streaming content, music and audio recordings, broadcast, radio, book publishing, video games and supplementary services and products. The U.S. M&E market, which is 33% of the global M&E industry, is the largest M&E market in the world. The U.S. M&E industry is expected to reach $804 billion by 2021, according to the Entertainment & Media Outlook by PriceWaterhouseCoopers.
                    </p>
                </div>
                <div className={classes.sliderSection}>
                    <Title>Automotive</Title>
                    <UnderlineBlue />
                    <p className={classes.sliderText}>
                        Our SMEs continue to lead the rapid Digital Transformation initiatives for the consumer facing brands (high visibility) leveraging our Agile Product Management Delivery Team.  Our Developers and DevOps Engineers continue to support the digital applications pipeline.  The overall result being a seamless digital customer experience along with future-proofed integration layers for connected vehicle APIs.
                    </p>
                    <p className={classes.sliderText}>
                        A new study predicts that over the next 15 years, the global automotive industry will undergo the greatest transformation in its history.  There will be an obvious increase in EV sales, however, a more important innovation expansion will be the connectivity of cars in 2025 and beyond.  Many vehicles will be permanently online, sending and receiving information via the internet.
                    </p>
                </div>
                <div className={classes.sliderSection}>
                    <Title>Gaming</Title>
                    <UnderlineBlue />
                    <p className={classes.sliderText}>
                        As a key part of the Gaming Development Pipeline, we implemented and support a complete Atlassian JIRA + Confluence upgrade and migration of several fragmented on-premise instances to a single Atlassian Cloud Solution without any user interruption.
                    </p>
                    <p className={classes.sliderText}>
                        The Gaming industry is constantly innovating and bringing new applications to market. VR sets, which include home, mobile and portable sets, are expected to increase sales in the up to 80 percent. Up 150%, to 1.08 billion. Today’s consumers have access to multiple devices for gaming, including PCs, mobile phones, digital or physical consoles, and tablets. The sector is comprised of: physical, digital, and online games; mobile apps; and virtual and augmented reality (VR/AR). Electronic sports, also known as “eSports” or “e-sports”, generally professional gaming, in which players compete before a live audience.
                    </p>
                </div>
                <div className={classes.sliderSection}>
                    <Title>Telecommunications</Title>
                    <UnderlineBlue />
                    <p className={classes.sliderText}>
                        In supporting the rapid Digital Transformation of our leading Fortune 500 Telecommunications client, we delivered Large scale Atlassian JIRA + Confluence + BitBucket Cloud Server implementation, support, and knowledge transfer.
                    </p>
                    <p className={classes.sliderText}>
                        Heading into {year} and beyond, telecommunications industry leaders are considering three key strategic opportunities to boldly position themselves to thrive in the future:  Renewing the focus on customers’ needs by taking a more nuanced approach to customer engagement; Converging and remixing entertainment experiences through new service offerings and entertainment bundles—and by adopting new strategies that can enable business agility; Repositioning to monetize advanced wireless networks (5G as an example) through new products, services, and business models.
                    </p>
                </div>
                <div className={classes.sliderSection}>
                    <Title>Finance & FinTech</Title>
                    <UnderlineBlue />
                    <p className={classes.sliderText}>
                        Agile Project Management solutions continue to support the rapid Digital Transformation of our Fintech clients.
                    </p>
                    <p className={classes.sliderText}>
                        In 2021 and onward, there is an increased drive for digital-first finance for the mobile-first generation.  2021 will be marked with ongoing “fintech-ization” of services, be it automated lending in mobile banking, plenty of payment options in food delivery apps or a step-by-step movement to a cashless economy. The fintech revolution is gaining momentum, so now is the time to leverage tech to build a better customer experience.
                    </p>
                </div>
            </Slider>
        </FlexColumnDiv>
    )
};

export default Industries;