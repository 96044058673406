import Header from '../components/header/Header';
import JobOpenings from '../components/job-openings/JobOpenings';
import Footer from '../components/footer/Footer';

const JobOpeningsPage = () => {
    return (
        <section className='backgroundCurve'>
            <Header />
            <JobOpenings />
            <Footer />
        </section>
    )
};

export default JobOpeningsPage;