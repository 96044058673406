import React from 'react';
import classes from './Header.module.css';
import NavBar from './nav-bar/NavBar';
import Wave from './wave/Wave';

const Header = () => {
    return (
        <React.Fragment>
        <header className={classes.header}>
            <Wave />
        </header>
        <NavBar />
        </React.Fragment>  
    )
};

export default Header;