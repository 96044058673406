import classes from './HappyBunch.module.css';
import Title from '../../UI/Title';
import UnderlineBlue from '../../UI/UnderlineBlue';
import HappyBunchImg from '../assets/happy_bunch.png';

const HappyBunch = () => {
    return (
        <div className={classes.wrapper}>
            <Title>We're a happy bunch</Title>
            <UnderlineBlue />
            <div className={classes.imgWrapper}><img src={HappyBunchImg} alt="Happy Bunch" /></div>
        </div>
    )
}

export default HappyBunch;