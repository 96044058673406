import classes from './CloudSolutions.module.css';
import Title from '../UI/Title';
import UnderlineBlue from '../UI/UnderlineBlue';
import Text from '../UI/Text';
import FlexColumnDiv from '../UI/FlexColumnDiv';
import logosImg from './assets/logosinclouds.png';

const CloudSolutions = () => {
    return (
        <div className={classes.cloudContainer}>
            <FlexColumnDiv>
                <Title>Cloud Solutions</Title>
                <UnderlineBlue />
                <Text>We procure, implement and support the following Cloud Solutions through our Licensed Partnerships.</Text>
            </FlexColumnDiv>
            <div className={classes.imgContainer}><img src={logosImg} alt="Brands" /></div>
        </div>   
    )
};

export default CloudSolutions;