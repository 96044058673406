import { FaLaptopCode, FaChartBar, FaDollarSign } from 'react-icons/fa';
import classes from './DevOps.module.css';
import DevOpsCard from './DevOpsCard';
import FlexColumnDiv from '../../UI/FlexColumnDiv';
import FlexRowDiv from '../../UI/FlexRowDiv';
import Title from '../../UI/Title';
import UnderlineBlue from '../../UI/UnderlineBlue';
import Text from '../../UI/Text';


const DevOps = () => {
    return (
        <FlexColumnDiv>
            <div className={classes.devops}>
                <Title>Unparalleled value for cloud transformation teams</Title>
                <UnderlineBlue />
                <Text>Support your entire digital team - from Development to Operations to Business - with a platform that works seamlessly across your full stack to improve collaboration and drive better business outcomes.</Text>
            </div>
            <FlexRowDiv>
                <DevOpsCard 
                    icon={<FaLaptopCode color='#ae80c9' size='50px' />} 
                    title="DEVELOPMENT"
                    titleColor='#ae80c9'
                    subTitle="Innovate faster with higher quality & less risk" 
                    text="Automate and secure DevOps pipelines and increase innovation throughput, speed, and quality across the full software development lifecycle." 
                />
                <DevOpsCard 
                    icon={<FaChartBar color='#77a9f2' size='50px' />} 
                    title="OPERATIONS"
                    titleColor='#77a9f2'
                    subTitle="Operate efficiently and proactively" 
                    text="Predict and resolve problems before they impact users with precise, AI-powered answers." 
                />
                <DevOpsCard 
                    icon={<FaDollarSign color='#36c687' size='50px' />} 
                    title="BUSINESS" 
                    titleColor='#36c687'
                    subTitle="Continuously drive better business outcomes" 
                    text="Boost conversions and revenue and consistently deliver the experiences customers expect." 
                />
            </FlexRowDiv>
        </FlexColumnDiv>
    )
}

export default DevOps;