import Header from '../components/header/Header';
import WhyChooseUs from '../components/why-choose-us/WhyChooseUs';
import Footer from '../components/footer/Footer';

const WhyChooseUsPage = () => {
    return (
        <section className='backgroundCurve'>
            <Header />
            <WhyChooseUs />
            <Footer />
        </section>
    )
};

export default WhyChooseUsPage;